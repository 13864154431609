import { create } from "zustand";
import { persist } from "zustand/middleware";

interface TextVideoList {
  name: string;
  url: string;
}

interface ImageVideoList {
  name: string;
  url: string;
}

interface TranslateVideoList {
  name: string;
  url: string;
}

interface VideoToTextList {
  name: string;
  url: string;
}

interface aigcVideoStore {
  // true为光照，false为暗黑
  myTheme: boolean;
  textVideoList: TextVideoList[];
  imageVideoList: ImageVideoList[];
  translateVideoList: TranslateVideoList[];
  videoToTextList: VideoToTextList[];
  setMyTheme: (flag: boolean) => void;
  setTextVideoList: (url: string, name: string) => void;
  setImageVideoList: (url: string, name: string) => void;
  setTranslateVideoList: (url: string, name: string) => void;
  setVideoToTextList: (url: string, name: string) => void;
  delTextVideoList: (url: string) => void;
  delImageVideoList: (url: string) => void;
  delTranslateVideoList: (url: string) => void;
  delVideoToTextList: (url: string) => void;
  updateTextVideoList: (url: string, name: string) => void;
  updateImageVideoList: (url: string, name: string) => void;
  updateTranslateVideoList: (url: string, name: string) => void;
  updateVideoToTextList: (url: string, name: string) => void;
}

export const useAigcVideoStore = create<aigcVideoStore>()(
  persist(
    (set, get) => ({
      // true为光照，false为暗黑,默认为黑暗
      myTheme: false,
      textVideoList: [],
      imageVideoList: [],
      translateVideoList: [],
      videoDubbingList: [],
      videoToTextList: [],
      setMyTheme(flag: boolean) {
        set((state) => ({ myTheme: flag }));
      },
      setTextVideoList(url: string, name: string) {
        !!url &&
          set((state) => ({
            textVideoList: [{ url, name }].concat(state.textVideoList),
          }));
      },
      setImageVideoList(url: string, name: string) {
        !!url &&
          set((state) => ({
            imageVideoList: [{ url, name }].concat(state.imageVideoList),
          }));
      },
      setTranslateVideoList(url: string, name: string) {
        !!url &&
          set((state) => ({
            translateVideoList: [{ url, name }].concat(
              state.translateVideoList
            ),
          }));
      },
      setVideoToTextList(url: string, name: string) {
        !!url &&
          set((state) => ({
            videoToTextList: [{ url, name }].concat(state.videoToTextList),
          }));
      },

      delTextVideoList(url: string) {
        !!url &&
          set((state) => {
            const arr = state.textVideoList.filter((item) => item.url !== url);
            return {
              textVideoList: arr,
            };
          });
      },
      delImageVideoList(url: string) {
        !!url &&
          set((state) => {
            const arr = state.imageVideoList.filter((item) => item.url !== url);

            return {
              imageVideoList: arr,
            };
          });
      },
      delTranslateVideoList(url: string) {
        !!url &&
          set((state) => {
            const arr = state.translateVideoList.filter(
              (item) => item.url !== url
            );
            return {
              translateVideoList: arr,
            };
          });
      },
      delVideoToTextList(url: string) {
        !!url &&
          set((state) => {
            const arr = state.videoToTextList.filter(
              (item) => item.url !== url
            );
            return {
              videoToTextList: arr,
            };
          });
      },

      updateTextVideoList(url: string, name: string) {
        !!url &&
          set((state) => {
            const arr = state.textVideoList.map((cItem) => {
              if (cItem.url === url) {
                return { url: url, name: name };
              }
              return cItem;
            });

            return { textVideoList: arr };
          });
      },
      updateImageVideoList(url: string, name: string) {
        !!url &&
          set((state) => {
            const arr = state.imageVideoList.map((cItem) => {
              if (cItem.url === url) {
                return { url: url, name: name };
              }
              return cItem;
            });

            return { imageVideoList: arr };
          });
      },
      updateTranslateVideoList(url: string, name: string) {
        !!url &&
          set((state) => {
            const arr = state.translateVideoList.map((cItem) => {
              if (cItem.url === url) {
                return { url: url, name: name };
              }
              return cItem;
            });

            return { translateVideoList: arr };
          });
      },
      updateVideoToTextList(url: string, name: string) {
        !!url &&
          set((state) => {
            const arr = state.videoToTextList.map((cItem) => {
              if (cItem.url === url) {
                return { url: url, name: name };
              }
              return cItem;
            });

            return { videoToTextList: arr };
          });
      },
    }),

    {
      name: "aigcVideo", // unique name
    }
  )
);

import { STATE } from "@/lib/enums";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface TaskProps {
  id: string;
  name: string;
  isGenerate: boolean;
  state: STATE;
  time?: number;
}

interface VideoTaskDataVideoProps {
  video: { tasks: TaskProps[] };
  [key: string]: { tasks: TaskProps[] };
}

interface VideoTaskDataAudioDubbingProps {
  video: { tasks: TaskProps[] };
  dubbing: { tasks: (TaskProps & { voiceList: (string | number)[] })[] };
  [key: string]: {
    tasks: TaskProps[] | (TaskProps & { voiceList: (string | number)[] })[];
  };
}

interface VideoTaskDataTranslateDubbingProps {
  video: { tasks: TaskProps[] };
  dubbing: {
    tasks: (TaskProps & {
      voiceList: (string | number)[];
      videoUrl: string;
      model: string[];
    })[];
  };
  [key: string]: {
    tasks:
      | TaskProps[]
      | (TaskProps & {
          voiceList: (string | number)[];
          videoUrl: string;
          model: string[];
        })[];
  };
}

export type VideoTaskType =
  | VideoTaskDataVideoProps
  | VideoTaskDataAudioDubbingProps
  | VideoTaskDataTranslateDubbingProps;

export type VideoTaskFnType =
  | ((newData: VideoTaskDataVideoProps) => void)
  | ((newData: VideoTaskDataAudioDubbingProps) => void)
  | ((newData: VideoTaskDataTranslateDubbingProps) => void);

// 创建生成任务
interface CreatePageTasksProps {
  video: { tasks: TaskProps[] };
}

// 创建可选任务
interface CreateSelectPageTasksProps {
  video: { tasks: TaskProps[] };
}

// ppt
interface CreatePPTPageTasksProps {
  video: { tasks: TaskProps[] };
}

//翻译
interface VideoTranslateTasksProps {
  dubbing: {
    tasks: (TaskProps & {
      voiceList: (string | number)[];
      videoUrl: string;
      model: string[];
    })[];
  };
  video: { tasks: TaskProps[] };
}

//视频配音
interface VideoDubbingAudioTasksProps {
  dubbing: { tasks: (TaskProps & { voiceList: (string | number)[] })[] };
  video: { tasks: TaskProps[] };
}

//视频转文字
interface VideoToTextTasksProps {
  video: { tasks: TaskProps[] };
}

interface aigcVideoTasksStore {
  createPageTask: CreatePageTasksProps;
  createSelectPageTask: CreateSelectPageTasksProps;
  createPPTPageTask: CreatePPTPageTasksProps;
  videoTranslateTask: VideoTranslateTasksProps;
  videoDubbingAudioTask: VideoDubbingAudioTasksProps;
  videoToTextTask: VideoToTextTasksProps;
  setCreatePageTask: (newCreatePageTask: CreatePageTasksProps) => void;
  setCreateSelectPageTask: (
    newCreateSelectPageTask: CreateSelectPageTasksProps
  ) => void;
  setCreatePPTPageTask: (newCreatePPTPageTask: CreatePPTPageTasksProps) => void;
  setVideoTranslateTask: (
    newVideoTranslateTask: VideoTranslateTasksProps
  ) => void;
  setVideoDubbingAudioTask: (
    newVideoDubbingAudioTask: VideoDubbingAudioTasksProps
  ) => void;
  setVideoToTextTask: (newVideoToTextTask: VideoToTextTasksProps) => void;
}

export const useVideoGenerateStateStore = create<aigcVideoTasksStore>()(
  persist(
    (set) => ({
      createPageTask: {
        video: {
          tasks: [],
        },
      },
      createSelectPageTask: {
        video: {
          tasks: [],
        },
      },
      createPPTPageTask: {
        video: {
          tasks: [],
        },
      },
      videoTranslateTask: {
        dubbing: {
          tasks: [],
        },
        video: {
          tasks: [],
        },
      },
      videoDubbingAudioTask: {
        dubbing: {
          tasks: [],
          voiceList: [],
        },
        video: {
          tasks: [],
        },
      },
      videoToTextTask: {
        video: {
          tasks: [],
        },
      },
      setCreatePageTask(newCreatePageTask: CreatePageTasksProps) {
        !!newCreatePageTask &&
          set(() => ({
            createPageTask: newCreatePageTask,
          }));
      },
      setCreateSelectPageTask(
        newCreateSelectPageTask: CreateSelectPageTasksProps
      ) {
        !!newCreateSelectPageTask &&
          set(() => ({
            createSelectPageTask: newCreateSelectPageTask,
          }));
      },
      setCreatePPTPageTask(newCreatePPTPageTask: CreatePPTPageTasksProps) {
        !!newCreatePPTPageTask &&
          set(() => ({
            createPPTPageTask: newCreatePPTPageTask,
          }));
      },
      setVideoTranslateTask(newVideoTranslateTask: VideoTranslateTasksProps) {
        !!newVideoTranslateTask &&
          set(() => ({
            videoTranslateTask: newVideoTranslateTask,
          }));
      },
      setVideoDubbingAudioTask(
        newVideoDubbingAudioTask: VideoDubbingAudioTasksProps
      ) {
        !!newVideoDubbingAudioTask &&
          set(() => ({
            videoDubbingAudioTask: newVideoDubbingAudioTask,
          }));
      },
      setVideoToTextTask(newVideoToTextTask: VideoToTextTasksProps) {
        !!newVideoToTextTask &&
          set(() => ({
            videoToTextTask: newVideoToTextTask,
          }));
      },
    }),
    { name: "aigcVideoTasks" }
  )
);

import api from "./api/api";
import {
  PostTranslateLingoSelectApiProps,
  PostTranslateTextApiProps,
  TranslateVideoGenerateProp,
  TranslateVideoUploadProp,
  UpdateDubbingDataApiProps,
  postTranslateVideoUploadApiResponseProps,
} from "./api.types";

export const postTranslateVideoUploadApi = async (
  data: TranslateVideoUploadProp
): Promise<{
  data: postTranslateVideoUploadApiResponseProps;
}> => {
  return await api.post("/api/video/analysis", data);
};

export const postTranslateVideoGenerateApi = async (
  params: TranslateVideoGenerateProp
) => {
  return await api.post("/api/video/translation", params);
};

export const postTranslateTextApi = async (
  params: PostTranslateTextApiProps
) => {
  return await api.post("/api/text/translation", params);
};

// 术语库
export const postTranslateLingoSelectApi = async (
  params: PostTranslateLingoSelectApiProps
): Promise<{ data: { model: string[] } }> => {
  return await api.post("/api/term/list", params);
};

export const updateDubbingDataApi = async (data: UpdateDubbingDataApiProps) =>
  api.post("/api/modify_status", data);

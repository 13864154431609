import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import { FC, memo } from "react";
import {
  CascaderLanguage,
  LanguageSelect,
  SelectSpeakingSpeed,
} from "../languageSelect";
import { SelectSubtitle } from "../select-subtitle/select-subtitle";
import { BGM, EXPORT_SUBTITLE_OPTIONS } from "@/lib/constants";
import { SettingModalProps } from "./props";
import useAction from "./hooks";

export const SettingModal: FC<SettingModalProps> = memo((props) => {
  const {
    form,
    data,
    updateLoading,
    newItemName,
    termSelectOptionsValues,
    addItem,
    deleteItem,
    onFinish,
    setNewItemName,
    onFormValuesChange,
  } = useAction(props);

  return (
    <Modal
      title="默认配置"
      width={1000}
      centered
      maskClosable={false}
      open={props.open}
      forceRender
      footer={
        <>
          <Button onClick={() => props.onClose()}>取消</Button>
          <Popconfirm
            title="警告"
            description="要删除当前配置？"
            okText="确认"
            cancelText="取消"
            onConfirm={() => deleteItem()}
          >
            <Button type="primary" danger loading={updateLoading}>
              删除
            </Button>
          </Popconfirm>

          <Button
            type="primary"
            loading={updateLoading}
            onClick={() => {
              form.submit();
            }}
          >
            保存
          </Button>
        </>
      }
      onCancel={props.onClose}
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 24 }}
        style={{ marginTop: 20, height: 750, overflow: "auto" }}
        onFinish={(value) => {
          onFinish(value);
        }}
      >
        <Form.Item label="使用中" name={"using"} labelCol={{ span: 2 }}>
          <Select
            style={{ width: 300 }}
            placeholder="请选择配置"
            options={[
              ...(data?.data?.map((item) => ({
                label: <>{item.name}</>,
                value: item.id,
              })) ?? []),
            ]}
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      style={{ width: 200 }}
                      placeholder="输入名称"
                      value={newItemName}
                      onChange={(e) => setNewItemName(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      onClick={() => {
                        addItem(newItemName);
                      }}
                    >
                      新增
                    </Button>
                  </Space>
                </>
              );
            }}
            onChange={(value) => {
              if (data) {
                onFormValuesChange(data, value);
              }
            }}
          />
        </Form.Item>
        <Form.Item label="创建生成任务">
          <Row>
            <Col offset={1}>
              <Form.Item label="语速" name={"text_video_speaking_speed"}>
                <SelectSpeakingSpeed />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label="字幕" name={"text_video_subtitle"}>
                <SelectSubtitle />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label="背景音乐" name={"text_video_bgc_music"}>
                <LanguageSelect placeholder="背景音乐" data={BGM} />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label="人声" name={"text_video_voice"}>
                <CascaderLanguage disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="创建可选任务" labelCol={{ span: 24 }}>
          <Row>
            <Col offset={1}>
              <Form.Item label="语速" name={"text_select_video_speaking_speed"}>
                <SelectSpeakingSpeed />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label="字幕" name={"text_select_video_subtitle"}>
                <SelectSubtitle />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label="背景音乐" name={"text_select_video_bgc_music"}>
                <LanguageSelect placeholder="背景音乐" data={BGM} />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label="人声" name={"text_select_video_voice"}>
                <CascaderLanguage disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="创建配音任务" labelCol={{ span: 24 }}>
          <Row>
            <Col offset={1}>
              <Form.Item label="语速" name={"dubbing_speaking_speed"}>
                <SelectSpeakingSpeed />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label="人声" name={"dubbing_voice"}>
                <CascaderLanguage />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="幻灯片生成视频">
          <Row>
            <Col offset={1}>
              <Form.Item label="语速" name={"ppt_speaking_speed"}>
                <SelectSpeakingSpeed />
              </Form.Item>
            </Col>

            <Col offset={1}>
              <Form.Item label="字幕" name={"ppt_subtitle"}>
                <SelectSubtitle />
              </Form.Item>
            </Col>

            <Col offset={1}>
              <Form.Item label="背景音乐" name={"ppt_bgc_music"}>
                <LanguageSelect placeholder="背景音乐" data={BGM} />
              </Form.Item>
            </Col>

            <Col offset={1}>
              <Form.Item label="人声" name={"ppt_voice"}>
                <CascaderLanguage disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="视频语种翻译">
          <Row>
            <Col offset={1}>
              <Form.Item label="语速" name={"translate_speaking_speed"}>
                <SelectSpeakingSpeed />
              </Form.Item>
            </Col>

            <Col offset={1}>
              <Form.Item label="人声" name={"translate_voice"}>
                <CascaderLanguage />
              </Form.Item>
            </Col>

            <Col offset={1}>
              <Form.Item label="模型" name={"translate_model"}>
                <LanguageSelect
                  placeholder="选择模型"
                  data={[
                    { label: "通用1", value: 0 },
                    { label: "通用2", value: 1 },
                    { label: "通用3", value: 2 },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col offset={1}>
              <Form.Item label="术语库" name={"translate_term"}>
                <Select
                  placeholder="选择术语库"
                  style={{ width: 120 }}
                  options={[
                    { label: "无", value: "null" },
                    ...termSelectOptionsValues.map((item) => ({
                      label: item,
                      value: item,
                    })),
                  ]}
                />
              </Form.Item>
            </Col>

            <Col offset={1}>
              <Form.Item label="字幕" name={"translate_subtitle"}>
                <SelectSubtitle />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="视频文案总结">
          <Row>
            <Col offset={1}>
              <Form.Item label="语种" name={"video_to_text_voice"}>
                <Select
                  allowClear
                  style={{ width: 120 }}
                  placeholder="选择语种"
                  options={EXPORT_SUBTITLE_OPTIONS}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
});

import { memo, FC } from "react";
import { Card, Checkbox, Drawer, Flex, Input, Tooltip } from "antd";
import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import useAction from "./hooks";
import { ButtonSelf, ViewCard } from "@/components";

const VideoTextList: FC = memo(({}) => {
  const {
    myTheme,
    videoList,
    editNameFlag,
    downloadOpen,
    videos,
    storeKey,
    deleteVideo,
    reviseVideoName,
    inputOnBlur,
    editVideoNameClick,
    setDownloadOpen,
    downloadVideos,
    setVideos,
  } = useAction();

  return (
    <div style={{ paddingBottom: 20 }}>
      <Card
        className={styles["video-list"]}
        extra={
          <Flex gap={20}>
            <ButtonSelf onClick={() => setDownloadOpen(true)} flag={true}>
              批量下载
            </ButtonSelf>
          </Flex>
        }
      >
        <div className={styles["video-container"]}>
          {videoList?.map((item, index) => (
            // key用index会自动打开下一个视频
            <div
              className={styles["video-content"]}
              style={{
                width: storeKey === "videoToTextList" ? 150 : 300,
              }}
              key={item.url}
            >
              {storeKey === "videoToTextList" ? (
                <Flex justify="center" align="center">
                  <FileTextOutlined style={{ fontSize: 60 }} />
                </Flex>
              ) : (
                <ViewCard
                  isExpand={true}
                  videoSrc={item.url}
                  onDelete={() => deleteVideo(item.url)}
                />
              )}

              {/* 视频名称 */}
              <div
                className={`${styles["video-name"]} ${
                  !myTheme && styles["text-color-night"]
                }`}
              >
                {!!editNameFlag[index] && (
                  <>
                    <Input
                      defaultValue={item.name}
                      bordered={false}
                      classNames={{ input: styles["input"] }}
                      onPressEnter={(e) =>
                        inputOnBlur(item.url, e.currentTarget.value)
                      }
                      onBlur={(e) => {
                        inputOnBlur(item.url, e.currentTarget.value);
                      }}
                    ></Input>
                  </>
                )}
                {!editNameFlag[index] && (
                  <>
                    <Tooltip
                      title={item.name ? item.name : null}
                      autoAdjustOverflow
                    >
                      <p className={styles["video-name-text"]}>
                        {reviseVideoName(item.name, index)}
                      </p>
                    </Tooltip>
                    <div className={styles["edit-icon"]}>
                      <EditOutlined onClick={() => editVideoNameClick(index)} />
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </Card>
      <Drawer
        onClose={() => setDownloadOpen(false)}
        open={downloadOpen}
        extra={
          <ButtonSelf
            style={{ width: 64 }}
            onClick={() => downloadVideos(videos)}
            flag={true}
          >
            下载
          </ButtonSelf>
        }
      >
        <div className={styles["download-item"]}>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setVideos(
                  videoList.map((item, index) => ({
                    name: item?.name ? item.name : `未命名视频${index}`,
                    url: item?.url,
                  }))
                );
              } else {
                setVideos([]);
              }
            }}
          >
            全选
          </Checkbox>
        </div>
        <Checkbox.Group
          className={styles["check-box-group"]}
          value={videos.map((item) => JSON.stringify(item))}
          onChange={(checkedValue) => {
            setVideos(checkedValue.map((item) => JSON.parse(String(item))));
          }}
        >
          {videoList.map((item, index) => (
            <div key={item.url} className={styles["download-item"]}>
              <Checkbox
                value={JSON.stringify({
                  name: item?.name ? item.name : `未命名视频${index}`,
                  url: item?.url,
                })}
              >
                {item?.name ? item.name : `未命名视频${index}`}
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </Drawer>
    </div>
  );
});

export default VideoTextList;

import { Route, Routes } from "react-router-dom";
import TranslateVideo from "./TranslateVideo";
import TextToVideoRoute from "./TextToVideo";
import ImagepptToVideo from "./ImagepptToVideo";
import VideoTextList from "./VideoTextList";
import CreateVideoDubbingRoute from "./video-dubbing-route";
import Home from "@/pages/home";
import AudioText from "@/pages/audio-text";
import Login from "@/pages/Login";
import { Check } from "./Check";
import VideoToText from "@/pages/VideoToText";

const HomeRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Check element={<Home />} />}>
        <Route path="TextToVideoRoute/*" element={<TextToVideoRoute />} />
        <Route path="ImageText/*" element={<ImagepptToVideo />} />
        <Route path="videoTranslate/*" element={<TranslateVideo />} />
        <Route path="videoList/*" element={<VideoTextList />} />
        <Route
          path="CreateVideoDubbingRoute/*"
          element={<CreateVideoDubbingRoute />}
        />
        <Route
          path="aidioTextRoute"
          element={<Check element={<AudioText />} />}
        />
        <Route
          path="vodeoToTextRoute"
          element={<Check element={<VideoToText />} />}
        />
        <Route path="*" element={<></>} />
      </Route>
    </Routes>
  );
};

export default HomeRoutes;
